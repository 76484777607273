<template>
    <div class="app-container">
        <el-form :model="query" ref="queryForm" :inline="true" label-width="110px">
            <el-form-item label="购买人手机号" prop="phone">
                <el-input v-model="query.phone" placeholder="请输入购买人手机号" clearable size="small"
                    @keyup.enter.native="handleQuery" />
            </el-form-item>
            <el-form-item label="收益人手机号" prop="leaderPhone" >
                <el-input v-model="query.leaderPhone" placeholder="请输入收益人手机号" clearable size="small"
                    @keyup.enter.native="handleQuery" />
            </el-form-item>
            <el-form-item label="下单日期" >
                <el-date-picker
                    v-model="dateList"
                    type="daterange"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    placeholder="选择日期"
                    format="yyyy 年 MM 月 dd 日"
                    value-format="yyyy-MM-dd"
                    @change="selectDate"
                >
                </el-date-picker>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
                <el-button icon="el-icon-refresh" size="mini" @click="reset">重置</el-button>
            </el-form-item>
        </el-form>

        <el-table v-loading="loading" :data="list" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55" align="center" />
            <el-table-column label="订单号" align="center" prop="orderSn" />
            <el-table-column label="购买人头像" align="center" prop="payHeadImg">
                <template v-slot="slot">
                    <div v-if="slot.row.payHeadImg == null || slot.row.payHeadImg == ''">未上传头像</div>
                    <img :src="slot.row.payHeadImg" width="80px" alt="image error" v-else>
                </template>
            </el-table-column>
            <el-table-column label="购买人昵称" align="center" prop="payName" />
            <el-table-column label="购买人手机号" align="center" prop="payPhone" />
            <el-table-column label="支付金额" align="center" prop="payMoney" >
                <template #default="scope">
                    <span style="font-size: 18px; color: red; font-weight: bold;">￥{{ (scope.row.payMoney || 0).toFixed(2) }}</span>
                </template>
            </el-table-column>
            <el-table-column label="佣金" align="center" prop="saleMoney" >
                <template #default="scope">
                    <span style="font-size: 18px; color: red; font-weight: bold;">￥{{ (scope.row.saleMoney || 0).toFixed(2) }}</span>
                </template>
            </el-table-column>
            <el-table-column label="收益人头像" align="center" prop="headImg">
                <template v-slot="slot">
                    <div v-if="slot.row.headImg == null || slot.row.headImg == ''">未上传头像</div>
                    <img :src="slot.row.headImg" width="80px" alt="image error" v-else>
                </template>
            </el-table-column>
            <el-table-column label="收益人昵称" align="center" prop="nickname" />
            <el-table-column label="收益人手机号" align="center" prop="phone" />
            <el-table-column label="收益类型" align="center" prop="saleType" >
                <template #default="scope">
                    {{ scope.row.saleType == 1?'一级':'二级' }}
                </template>
            </el-table-column>
            <el-table-column label="收益状态" align="center" prop="state">
                <template slot-scope="scope">
                    <el-tag :type="orderStatusDict[scope.row.state].color">{{ orderStatusDict[scope.row.state].label}}</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="下单时间" align="center" prop="createTime" />
            
        </el-table>

        <pagination v-show="total > 0" :total="total" :page.sync="query.current" :limit.sync="query.size"
            @pagination="getList" />
    </div>
</template>

<script>
import api from '@/api/commonApi'

export default {
    name: 'Brokerage',
    data() {
        return {
            // 列表
            list: [],
            // 选择日期
            dateList: [],
            // 类型
            dictType: [],
            // 总条数
            total: 0,
            // 遮罩层
            loading: false,
            // 查询
            query: {
                current: 1,
                size: 10,
                type: 1,
                phone: '',
                leaderPhone: '',
                startDate: '',
                endDate: '',
            },
            // 选择
            ids: [],
            // 非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
            // 状态
            orderStatusDict: {
                '-1': { color: 'info', label: '已退款' },
                '0': { color: 'warning', label: '冻结中' },
                '1': { color: '', label: '待提现' },
                '2': { color: 'success', label: '提现中' },
                '3': { color: 'danger', label: '已提现' },
            },
        }
    },
    created() {
        this.getList()
    },
    methods: {
        // 获取列表数据
        getList() {
            this.loading = true
            api.memberSaleList(this.query).then(res => {
                this.total = res.total
                this.list = res.records
                this.loading = false
            })
        },
        // 搜索
        handleQuery() {
            this.getList()
        },
        /** 选择日期 */
        selectDate(e) {
            console.log(e);
            if (e != null && e.length > 0) {
                this.query.current = 1
                this.query.startDate = e[0]
                this.query.endDate = e[1]
                this.getList()
            }else {
                this.query.current = 1
                this.query.startDate = ''
                this.query.endDate = ''
                this.getList()
            }
        },
        // 重置
        reset() {
            this.query = {
                current: 1,
                size: 10,
                type: 1,
                phone: '',
                leaderPhone: '',
                startDate: '',
                endDate: '',
            }
            this.dateList = [],
            this.getList()
        },
        /** 多选框选中数据 */
        handleSelectionChange(selection) {
            this.ids = selection.map(item => item.id)
            this.single = selection.length !== 1
            this.multiple = !selection.length
        },
    }
}

</script>